.idOrders{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
    color: #262729 !important;
}
.fullName{
    font-size: 14px !important;
  line-height: 1.5 !important;
  color: #262729 !important;
}
.companyName{
    font-size: 12px !important;
    line-height: 2 !important;
    color: #6c6e75 !important;
}

.btn_border{
    color: #494a4e !important;
    border: solid 1px #dadbdc !important;
    padding: 5px 14px !important;
    border-radius: 4px !important;
    text-decoration: none !important;
    display: block !important;
    text-align: center !important;
    transition: .3s !important;
    &:hover{
      border-color: #556f99 !important;
      transition: .3s !important; 
      
    }
  }
  .table {
    &  tbody tr{
      &:nth-child(even){
        background-color: #f4f4f5;
      }
      & td {
        border-bottom: 1px solid #eee !important;

      }
    }
  }
  .userInfo{
    min-width: 190px !important;
    text-align: left;
  }
  .table{
     
      & th{
        text-align: center;
    }
      & td{
        text-align: center;
    }
    
    
}