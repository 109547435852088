.fullName{
    font-size: 14px !important;
  line-height: 1.5 !important;
  color: #262729 !important;
}
.companyName{
    font-size: 12px !important;
    line-height: 2 !important;
    color: #6c6e75 !important;
}
.userInfo{
    min-width: 190px !important;
    text-align: left;
  }